import React, { useCallback, useMemo, useState ,useEffect,useContext} from 'react';
import axios from "axios";
import Swal from "sweetalert2";  

import Header from "../../components/Header";

import MaterialReactTable ,
{ MRT_FullScreenToggleButton,
  MRT_ShowHideColumnsButton,
  MRT_ToggleGlobalFilterButton, } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import {
  Alert,
  Box,
  Button,
  IconButton,
  Tooltip,
} from '@mui/material';

import Snackbar from '@mui/material/Snackbar';
import { Delete, Edit,Add } from '@mui/icons-material';
import { inicialArticulo} from '../../data/makeDataCrud';
import CloudDownload from "@mui/icons-material/CloudDownload";
import Print from "@mui/icons-material/Print";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import {  ActionIcon } from '@mantine/core';

import {ModalArticulo} from './modal/ModalArticulo';

import { AuthContext } from "../../mycontext";

const URL_BASE=process.env.REACT_APP_URL_BASE;
const API_UPD_SFA=process.env.REACT_APP_API_UPD_SFA;
const API_SEL_SFA=process.env.REACT_APP_API_SEL_SFA;
const API_SEL_BOD=process.env.REACT_APP_API_SEL_BOD;
const API_SEL_FAR=process.env.REACT_APP_API_SEL_FAR;

const API_SEL=process.env.REACT_APP_API_SEL_ART;
const API_INS=process.env.REACT_APP_API_INS_ART;
const API_UPD=process.env.REACT_APP_API_UPD_ART;
const API_DEL=process.env.REACT_APP_API_DEL_ART;

const API_SEL_PAR=process.env.REACT_APP_API_SEL_PAR;



const Articulo = () => {

    const {user} = useContext(AuthContext);
  
    const { token } = user;

    const [titulomod, setTitulomod] = useState('');
  
    const [title, setTitle] = useState('');
  
    const [subTitle, setSubTitle] = useState('');

    const [valuePeriodo, setValuePeriodo] = useState('');
  
    const [isRefetching, setIsRefetching] = useState(false);
  
    const [isLoading, setIsLoading] = useState(false);
    
    const [errorPrueba, setErrorPrueba] = useState(false);
    
    const [errorText, setErrorText] = useState("");

    const [createModalOpen, setCreateModalOpen] = useState(false);

    const [tableData, setTableData] = useState([]);

    const [subfamData, setSubfamData] = useState([]);

    const [bodegaData, setBodegaData] = useState([]);

    const [familiaData, setFamiliaData] = useState([]);

    const [rowData, setRowData] = useState([]);

    const [inicial, setInicial] = useState(inicialArticulo); 

    const [deshabilitado, setDeshabilitado] = useState(false);

    //snack
    const [state] = useState({
      vertical: 'top',
      horizontal: 'center',
    });

    const { vertical, horizontal } = state;
    const [openSnack, setOpenSnack] = useState(false);
    const [message, setMessage] = useState(false);

    const updateSiguienteSubFamilia=(idfs,fsiguiente)=>{
      console.log("updateSiguienteSubFamilia ",idfs,fsiguiente);  

      setIsLoading(true);
      setIsRefetching(true);
  
     
        axios
        .put(`${URL_BASE}${API_UPD_SFA}${idfs}`, {
          data: {
              
              siguientecodigo: fsiguiente+1,
          },
        },{    headers:{
          Authorization:`Bearer  ${token}`
        }})
        .then((response) => {
          //console.log(response);
          setIsLoading(false);
          setIsRefetching(false);
          setMessage(`Modificado folio ${fsiguiente}`);
          setOpenSnack(true);
          cargaSubfamilia();
  
      }).catch((error) => {
  
        console.log(error);
        setErrorPrueba(true)
        setErrorText(JSON.stringify(error));
  
      });
  
   };
    

    const handleCreateNewRow = (values) => {
       
     
      setIsLoading(true);
      setIsRefetching(true);
      
      const idsubfam=subfamData.find(element=>element.descripcion===values.subfamilia_articulo);
      console.log("id idsubfam ",idsubfam.id);

      const idbodega=bodegaData.find(element=>element.descripcion===values.bodega);
      console.log("id bodega ",idbodega.id);          
      
      
      const agrabar= {
        codigo: values.codigo,
        descripcion: values.descripcion,
        codigodebarra: values.codigodebarra,
        codigoproveedor:values.codigoproveedor,
        unidaddemedida: values.unidaddemedida,
        preciodeventa: values.preciodeventa,
        precio: values.precio,
        ubicacion:values.ubicacion,
        costopromedio: values.costopromedio,
        maximodescuento: values.maximodescuento,
        modificapreciodeventa: values.modificapreciodeventa,
        stockmin:values.stockmin,
        stockmax:values.stockmax,
        tasaila:values.tasaila,
        subfamilia_articulo: idsubfam.id,
        bodega:idbodega.id,
        base:values.base,
        urlimagen:values.urlimagen,
        cosa:values.cosa,
        produccioninterna:values.produccioninterna
      };

      console.log("A GRABAR ",agrabar);

      axios
          .post(`${URL_BASE}${API_INS}`, {

            data:agrabar,
          },{    headers:{
            Authorization:`Bearer  ${token}`
          }})
          .then((response) => {
      
            console.log("reemplazando id =========>",response.data.data.id);  
            values.id=response.data.data.id;
            values.subfamilia_articulo=response.data.data.subfamilia_articulo;
            console.log("reemplazando subfamiliaarticulo =========>",values.subfamilia_articulo);  

            values.bodega=response.data.data.bodega;
            console.log("reemplazando bodega =========>",values.bodega); 
                        
            tableData.push(values);
            setTableData([...tableData]);

            updateSiguienteSubFamilia(response.data.data.subfamilia_articulo.id,
              response.data.data.subfamilia_articulo.siguientecodigo);
            
            setMessage(`Agregado ${values.id}`);
            setOpenSnack(true);

            setIsLoading(false);
            setIsRefetching(false);

            cargaSubfamilia();
          
        }).catch((error) => {

          console.log(error);
          setErrorPrueba(true);
          setErrorText(JSON.stringify(error));
          setIsLoading(false);
          setIsRefetching(false);

      });

    };
   

    const handleEdit= async (values ) => {

         
        console.log("modifica=====ok");
        console.log("los values edit ",values);   
        setIsLoading(true);
        setIsRefetching(true);
             
        const idsubfam=subfamData.find(element=>element.descripcion===values.subfamilia_articulo);
        console.log("id idsubfam ",idsubfam);
        values.subfamilia_articulo={id:idsubfam.id,descripcion:idsubfam.descripcion};

        const idbodega=bodegaData.find(element=>element.descripcion===values.bodega);
        console.log("id idbodega ",idbodega);
        values.bodega={id:idbodega.id,descripcion:idbodega.descripcion};        


        tableData[rowData.index] = values;

          axios
          .put(`${URL_BASE}${API_UPD}${rowData.getValue('id')}`, {
            data: {
             
              codigo: values.codigo,
              descripcion: values.descripcion,
              codigodebarra: values.codigodebarra,
              codigoproveedor:values.codigoproveedor,
              unidaddemedida: values.unidaddemedida,
              preciodeventa: values.preciodeventa,
              precio: values.precio,
              ubicacion:values.ubicacion,
              costopromedio: values.costopromedio,
              maximodescuento: values.maximodescuento,
              tasaila:values.tasaila,
              modificapreciodeventa: values.modificapreciodeventa,
              stockmin:values.stockmin,
              stockmax:values.stockmax,
              subfamilia_articulo: idsubfam.id,
              bodega: idbodega.id,
              base:values.base,
              urlimagen:values.urlimagen,
              produccioninterna:values.produccioninterna
              

            },
          },{    headers:{
            Authorization:`Bearer  ${token}`
          }})
          .then((response) => {
            console.log(response);

            setIsLoading(false);
            setIsRefetching(false);
            setMessage(`Modificado ${values.id}`);
            setOpenSnack(true);
 
        }).catch((error) => {

          console.log(error);
          setErrorPrueba(true)
          setErrorText(JSON.stringify(error));

        });
          
        setTableData([...tableData]);
         
    };


   const handleDeleteRow = useCallback(

      (row) => {
      
        console.log("id a borrar: ",row.getValue('id'));
         
        Swal.fire({  
          title: 'Seguro quiere borrar?',  
          
          icon: 'warning',  
          showCancelButton: true,  
          confirmButtonColor: '#3085d6',  
          cancelButtonColor: '#d33',  
          cancelButtonText: 'No',
          confirmButtonText: 'Si!'  

        }).then(response=>{

            if (response.isConfirmed){
              setIsLoading(true);
              setIsRefetching(true);
      
              //send api delete request here, then refetch or update local table data for re-render
              
              axios
              .delete(`${URL_BASE}${ API_DEL}${row.getValue('id')}`,{    headers:{
                Authorization:`Bearer  ${token}`
              }})
            
              .then((response) => {
                  console.log(response);
                  tableData.splice(row.index, 1);
                  setTableData([...tableData]);
                  
      
                  setIsLoading(false);
                  setIsRefetching(false);
      
                  setMessage(`Eliminado ${row.getValue('id')}`);
                  setOpenSnack(true);
                  
              })
              .catch((error) => {
                  setIsLoading(false);
                  setIsRefetching(false);
      
                  console.log(error);
                  setErrorPrueba(true);
                  setErrorText(JSON.stringify(error))
      
              });

            };

        });

  
      },
      [tableData,token],
    );

    const columns = useMemo(
      () => [
        {
          accessorKey: 'id',
          header: 'ID',
          enableColumnOrdering: false,
          enableEditing: false, //disable editing on this column
          enableSorting: false,
          size: 80,
        },
        {
          accessorKey: 'codigo',
          header: 'codigo',
          size: 140,

        },
        {
          accessorKey: 'descripcion',
          header: 'descripcion',
          size: 140,

        },
        {
          accessorKey: 'codigodebarra',
          header: 'codigodebarra',
          size: 140,

        },    
        {
          accessorKey: 'codigoproveedor',
          header: 'codigoproveedor',
          size: 140,

        }, 

        {
          accessorKey: 'unidaddemedida',
          header: 'unidaddemedida',
          size: 140,

        },         
        {
          accessorKey: 'ubicacion',
          header: ' ubicacion',
          size: 140,

        },   
        {
          accessorKey: 'costopromedio',
          header: 'costo promedio',
          size: 140,

        },
        {
          accessorKey: 'maximodescuento',
          header: 'maximo descuento',
          size: 140,

        },         
        {
          accessorKey: 'preciodeventa',
          header: 'preciodeventa',
          size: 140,

        },
        {
          accessorKey: 'precio',
          header: 'precio',
          size: 140,

        },        
        {
          accessorKey: 'modificaprecioventa',
          header: 'modificaprecioventa',
          size: 140,
          Cell: ({ cell }) => {
            return cell.getValue() ?  "si" : "no"
          },
        },
        {
          accessorKey: 'stockmin',
          header: 'stock min',
          size: 140,

        },
        {
          accessorKey: 'stockmax',
          header: 'stock max',
          size: 140,

        },     
        {
          accessorKey: 'tasaila',
          header: 'tasa ila',
          size: 140,

        },                                                                       
        {
          accessorKey: 'subfamilia_articulo.descripcion',
          header: 'sub-familia',
          size: 140,

        },
        {
          accessorKey: 'bodega.descripcion',
          header: 'bodega',
          size: 140,

        },  
        {
          accessorKey: 'produccioninterna',
          header: 'produccioninterna',
          size: 140,

        },                        
        {
          accessorKey: 'base',
          header: 'base',
          size: 140,
          Cell: ({ cell }) => {
            return cell.getValue() ?  "si" : "no"
          },
        },        
      ],
      [],
    );
  //       accessorFn: (row) => `${row.familia_producto.codigo} ${row.familia_producto.descripcion}`,
  
  //  accessorKey: 'familia_producto.descripcion',
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);

  };

 //const columnsCsv=['First Name','Last Name','Addres','City','State']
 const csvOptions = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: false,
  headers: columns.map((c) => c.header),
};
  
  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    let dataCsv=[];

    for(let i = 0;i<tableData.length;i++) { 

       const fila={id: tableData[i].id ,
                   codigo: tableData[i].codigo ,
                   descripcion: tableData[i].descripcion,
                   subfamilia_articulo: tableData[i].subfamilia_articulo.descripcion,
                    };
       dataCsv=[...dataCsv,fila];
      
    };
 
    csvExporter.generateCsv(dataCsv);
  };


  const get_data_to_pdf=()=>{
    let dataPdf=[];

    for(let i = 0;i<tableData.length;i++) { 

       const fila=[ tableData[i].id, 
                    tableData[i].codigo ,
                    tableData[i].descripcion,
                    tableData[i].subfamilia_articulo.descripcion,
                
       ];
       dataPdf=[...dataPdf,fila];
      
    };

    return dataPdf;
 
  }

  const get_column_to_pdf=()=>{
    let columnPdf=[];

    for(let i = 0;i<columns.length;i++) { 

       columnPdf=[...columnPdf,columns[i].header];
      
    };

    return columnPdf;
  }  

  const downloadPdf=()=>{
    const doc = new jsPDF()

    autoTable(doc, { html: '#my-table' })
   
    doc.text( title,15,10);
    autoTable(doc, {
      
      head: [get_column_to_pdf()],
      body: get_data_to_pdf(),
      
    })

    doc.save('table.pdf')
  };  




  const cargaSubfamilia=async()=>{
    
    await axios
    .get(`${URL_BASE}${API_SEL_SFA}`,{    headers:{
      Authorization:`Bearer  ${token}`
    }})
    .then(({ data }) => {
    
      setSubfamData([...data.data]);

      setIsLoading(false);
      setIsRefetching(false);

    })
    .catch((error) => {
      console.log("error")
      setErrorPrueba(true)
      setErrorText(JSON.stringify(error))
    
    });

  };



  useEffect(() => { 

   setTitulomod(''); 
   setTitle(inicialArticulo.title);
   setSubTitle(inicialArticulo.subtitle);
   setIsLoading(true);
   setIsRefetching(true);


  const cargaBodega=async()=>{
  
    await axios
    .get(`${URL_BASE}${API_SEL_BOD}`,{    headers:{
      Authorization:`Bearer  ${token}`
    }})
    .then(({ data }) => {
     
      setBodegaData(data.data);

      setIsLoading(false);
      setIsRefetching(false);

    })
    .catch((error) => {
      console.log("error")
      setErrorPrueba(true)
      setErrorText(JSON.stringify(error))
     
    });

  };  
  const cargaFamilia=async()=>{
  
    await axios
    .get(`${URL_BASE}${API_SEL_FAR}`,{    headers:{
      Authorization:`Bearer  ${token}`
    }})
    .then(({ data }) => {
     
      setFamiliaData(data.data);

      setIsLoading(false);
      setIsRefetching(false);

    })
    .catch((error) => {
      console.log("error")
      setErrorPrueba(true)
      setErrorText(JSON.stringify(error))
     
    });

  };  

  const cargaParametro=async()=>{
  
    await axios
    .get(`${URL_BASE}${API_SEL_PAR}`,{    headers:{
      Authorization:`Bearer  ${token}`
    }})
    .then(({ data }) => {
     
      setValuePeriodo(data.data[0].mesproceso);

      setIsLoading(false);
      setIsRefetching(false);

    })
    .catch((error) => {
      console.log("error")
      setErrorPrueba(true)
      setErrorText(JSON.stringify(error))
     
    });

  };  

   const urlapi=`${URL_BASE}${API_SEL}`
   console.log(urlapi);

   const cargaStrapi=async()=>{

       await axios
       .get(urlapi,{    headers:{
        Authorization:`Bearer  ${token}`
      }})
       .then(({ data }) => {

         console.log(data.data);
        
         setTableData(data.data);   

         cargaSubfamilia();

         cargaBodega();

         cargaFamilia();

         cargaParametro();
        

       })
       .catch((error) => {
         console.log("error")
         setErrorPrueba(true)
         setErrorText(JSON.stringify(error))
       
       });

  }; 

   cargaStrapi();
   
 },[] );  
  return (
    <>
 
     {  errorPrueba   &&
        <Alert onClose={() => setErrorPrueba(false)} variant="outlined" severity="error">
            {errorText} This is an error alert — check it out!
        </Alert>
      }  

      <Snackbar open={openSnack}
       autoHideDuration={3000} 
       onClose={handleClose}  

       anchorOrigin={{ vertical, horizontal }}
       key={vertical + horizontal}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
             {message}
        </Alert>
      </Snackbar>

      <Box m="20px">
        <Header
          title={title}
          subtitle={subTitle}
        />
      </Box>
      <MaterialReactTable
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        columns={columns}
        data={tableData}

        localization={MRT_Localization_ES}
        enableTopToolbar={true} //hide top toolbar
        enableBottomToolbar={true} //hide bottom toolbar
        renderToolbarInternalActions={({ table }) => (
          <>
            <ActionIcon
                      onClick={() => {
                        
                        // window.print();
                        downloadPdf();

                      }}
                    >
                      <Print />
            </ActionIcon>

            <ActionIcon
                onClick={() => {
                  handleExportData();
                }}
              >
                <CloudDownload/>
            </ActionIcon>

            {/* built-in buttons (must pass in table prop for them to work!) */}
            <MRT_ToggleGlobalFilterButton table={table}/>
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_FullScreenToggleButton table={table} />
          </>
        )}

        editingMode="modal" //default
        enableColumnOrdering
        enableEditing
        state={{
          isLoading,
          showProgressBars: isRefetching
        }}      
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="left" title="Edit">
              <IconButton onClick={() =>{
       

                console.log("modificando",row.original);
                setTitulomod(`${title} Modifica registro Id ${row.original.id}`);
                setRowData(row);  
                //getCompraArticulo(row.original.id);
                const modificar={
                 id: row.original.id,
                 codigo: row.original.codigo,
                 descripcion:  row.original.descripcion,
                 codigodebarra: row.original.codigodebarra,
                 codigoproveedor:row.original.codigoproveedor,
                 unidaddemedida: row.original.unidaddemedida,
                 ubicacion: row.original.ubicacion,
                 costopromedio: row.original.costopromedio,
                 maximodescuento: row.original.maximodescuento,
                 preciodeventa: row.original.preciodeventa,
                 precio: row.original.precio,
                 modificapreciodeventa: row.original.modificapreciodeventa,
                
                 stockmin:row.original.stockmin,
                 stockmax:row.original.stockmax,
                 tasaila:row.original.tasaila,                  

                 fechaultimaventa:row.original.fechaultimaventa,
                 ventasdelmes:row.original.ventasdelmes,
                 ventasacumuladas:row.original.ventasacumuladas,
                 
                 fechacumayor:row.original.fechacumayor,
                 cunipromedio:row.original.cunipromedio,
                 cunitmayor:row.original.cunitmayor,
                 costostock:row.original.costostock,
                 costototal:row.original.costototal,

                 fechaultimacompra:row.original.fechaultimacompra,
                 comprasdelmes:0,
                 comprasacumuladas:row.original.comprasacumuladas,
                 
                 subfamilia_articulo:  row.original.subfamilia_articulo,
                 bodega:  row.original.bodega,
                 base:    row.original.base,
                 urlimagen: row.original.urlimagen,
                 produccioninterna:row.original.produccioninterna

               };
               
               console.log("a modificar",modificar);
               setInicial(modificar);
               setDeshabilitado(true);
               setCreateModalOpen(true);

              }
               
              }> 
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button
            color="secondary"
            onClick={() => {
              setDeshabilitado(false);
              setInicial(inicialArticulo);
              setTitulomod(`${title} Nuevo registro`);
              
              setCreateModalOpen(true)}}
            variant="contained"
          >
              <Add/>
          </Button>
        )}
      />
      
      <ModalArticulo
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        onEdit={handleEdit}
        subfamData={subfamData}
        bodegaData={bodegaData}
        familiaData={familiaData}
        inicial={inicial}
        tableData={tableData}
        titulomod={titulomod}
        deshabilitado={deshabilitado}
        valuePeriodo={valuePeriodo}
        

      />

    </>
  );
};

export default Articulo;
